<template>
  <section>
    <b-card>
      <b-row>
        <b-col>
          <b-button-group>
            <b-button variant="primary" @click="gettoTree">Getir</b-button>
            <b-button
                variant="success"
                @click="addRack"
                size="sm"
            >Raf Ekle
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
    </b-card>

    <Rack v-for="rack in _totree" :rack="rack" :key="rack.id"/>

    <add-rack-modal/>
  </section>
</template>
<script>
import AddRackModal from "@/views/v2/stock/rack/AddRackModal.vue";
import Rack from "@/views/v2/stock/rack/Rack.vue";
import {mapGetters} from "vuex";

export default {
  name: 'RackHome',
  components: {AddRackModal, Rack},
  computed: {
    ...mapGetters('stockRack', ['_totree'])
  },
  methods: {
    gettoTree() {
      this.$store.dispatch('stockRack/getToTree');
    },
    addRack($event) {
      this.$root.$emit('add-rack-modal-data', {})
      this.$root.$emit('bv::show::modal', 'add-rack-modal', $event.target)
    }
  },
  mounted() {
    this.$store.dispatch('stockRack/getToTree');
  }
}
</script>


<style scoped>

</style>
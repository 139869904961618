<template>
  <b-modal
      id="add-rack-modal"
      title="Yeni Raf Ekle"
      cancel-variant="outline-secondary"
      cancel-title="Kapat"
      ok-title="Ekle"
      @ok="rackSave"
      ok-only
      no-close-on-backdrop
  >
    <div>
      <b-form-group label-cols="4" label="Üst Raf Kodu" size="sm">
        <b-form-input v-if="rack.rack" type="text" size="sm" v-model="rack.rack+'-'" disabled/>
        <b-form-input v-else type="text" size="sm" disabled/>
      </b-form-group>

      <b-form-group label-cols="4" label="Raf Kodu" size="sm">
        <b-form-input type="text" size="sm" v-model="newRack.rack"/>
        <small class="text-danger">Maksimum 3 karakter Girilmelidir.</small>
      </b-form-group>

      <b-form-group label-cols="4" label="Raf Adı" size="sm">
        <b-form-input type="text" size="sm" v-model="newRack.name"/>
        <small class="text-danger">Zorunlu değildir.</small>
      </b-form-group>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "AddRackModal",
  data: () => ({
    rack: {},
    newRack: {rack: '', name: ''}
  }),
  methods: {
    rackSave() {
      let payload = {
        parent_id: this.rack.id,
        rack: this.newRack.rack,
        name: this.newRack.name
      }
      this.$store.dispatch('stockRack/saveRackToTree', payload)
    }
  },
  mounted() {
    this.$root.$on('add-rack-modal-data', (payload) => {
      this.rack = payload;
    })
  }
}
</script>


<style scoped>

</style>
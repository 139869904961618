<template>

  <ul>
    <li class="mt-1">
      <b-badge variant="danger">{{ rack.rack }}</b-badge>
      <b-badge class="ml-2" v-if="rack.name" variant="info"> {{ rack.name }}</b-badge>
      <b-badge class="ml-2" variant="success" @click="addRack"> Alt Raf Ekle</b-badge>
    </li>
    <Rack v-for="rack1 in rack.children" :rack="rack1" :key="rack1.id"/>
  </ul>
</template>

<script>
export default {
  name: "Rack",
  props: {
    rack: {type: Object, required: true},
  },
  methods: {
    addRack($event) {
      this.$root.$emit('add-rack-modal-data', this.rack)
      this.$root.$emit('bv::show::modal', 'add-rack-modal', $event.target)
    }
  },
}
</script>

<style scoped>

</style>